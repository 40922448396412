







































import CancelButton from '@/components/buttons/CancelButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import CardTitle from '@/components/CardTitle.vue'
import EditButton from '@/components/buttons/EditButton.vue'
import CreateButton from '@/components/buttons/CreateButton.vue'
import DocumentTypeService from '@/services/documentTypes'
import DocumentTypeSelect from '@/components/selects/DocumentTypeSelect.vue'
import type { DocumentTypeDTO } from '@/services/documentTypes/types'
import { errorMessages } from '@/utils/helpers'
import { defineComponent } from '@vue/composition-api'
import { useErrors } from '@/components/composables/useErrors'

export default defineComponent({
  setup() {
    const { errors, clearFieldError } = useErrors()
    return { errors, clearFieldError }
  },
  name: 'DocumentTypeForm',
  components: { EditButton, CreateButton, CardTitle, SubmitButton, CancelButton, DocumentTypeSelect },
  props: {
    id: { type: Number, default: 0 },
    viewType: { type: String, default: 'create' },
  },
  data: function (): {
    dialog: boolean
    data: DocumentTypeDTO
  } {
    return {
      dialog: false,
      data: {
        name: '',
        id: 0,
        usage: '',
        required: false,
      },
    }
  },
  computed: {
    isCreateMode(): boolean {
      return this.viewType === 'create'
    },
    isEditMode(): boolean {
      return this.viewType === 'edit'
    },
  },
  methods: {
    closeDialog() {
      this.data.name = ''
      this.data.usage = ''
      this.dialog = false
    },
    async fetchItem(): Promise<void> {
      const loader = this.$loading.show()
      try {
        const { data } = await DocumentTypeService.find(this.id)
        this.data = data.data.documentType
      } finally {
        loader.hide()
      }
    },
    async save(): Promise<void> {
      const loader = this.$loading.show()
      try {
        const response = await DocumentTypeService.update(this.id, this.data)
        if (response) {
          this.$emit('updated')
          this.$toast.success(response.data.message)
          this.data = {
            name: '',
            id: 0,
            usage: '',
            required: false,
          }
          this.dialog = false
        }
      } catch (err) {
        if (err.response.data) {
          this.$toast.error(errorMessages(err.response.data.message))
          this.errors.value = err.response.data.errors || {}
          return
        }
        this.$toast.error(this.$t('somethingWentWrong') as string)
      } finally {
        loader.hide()
      }
    },
    async add(): Promise<void> {
      const loader = this.$loading.show()
      try {
        const response = await DocumentTypeService.create(this.data)
        if (response) {
          this.$emit('created')
          this.$toast.success(response.data.message)
          this.dialog = false
        }
      } catch (err) {
        if (err.response.data) {
          this.$toast.error(errorMessages(err.response.data.message))
          this.errors.value = err.response.data.errors || {}
          return
        }
        this.$toast.error(this.$t('somethingWentWrong') as string)
      } finally {
        loader.hide()
      }
    },
  },
})
