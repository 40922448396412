




























































import { formatDate } from '@/utils/helpers'
import { defineComponent } from '@vue/composition-api'
import type { Header } from '@/components/composables/useDataTable/types'
import { useDataTable } from '@/components/composables/useDataTable'
import DocumentTypeForm from '@/views/DocumentType/DocumentTypeForm.vue'
import DeleteButton from '@/components/buttons/DeleteButton.vue'
import DocumentTypeService from '@/services/documentTypes'

export default defineComponent({
  setup() {
    const { items, totalRecords, loading, pagination, search, query, pages, fetchData } =
      useDataTable(DocumentTypeService)

    return {
      items,
      totalRecords,
      loading,
      pagination,
      search,
      query,
      pages,
      fetchData,
      formatDate,
      DocumentTypeService,
    }
  },
  name: 'DocumentTypeList',
  components: { DeleteButton, DocumentTypeForm },
  computed: {
    headers(): Array<Header> {
      return [
        { value: 'name', text: this.$t('documents.name'), sortable: true },
        { value: 'name', text: this.$t('documents.type'), sortable: true },
        { value: 'actions', text: this.$t('actions'), sortable: false, width: '150px' },
      ]
    },
  },
})
