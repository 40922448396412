import api from '@/plugins/api'
import type {
  CreateDocumentTypeResponse,
  DeleteDocumentTypeResponse,
  FindDocumentTypeResponse,
  GetAllDocumentTypeResponse,
  UpdateDocumentTypeResponse,
} from './types'

const DocumentTypeService = {
  all(query = ''): Promise<GetAllDocumentTypeResponse> {
    return api().get(`/document-types${query}`)
  },

  find(id: string | number): Promise<FindDocumentTypeResponse> {
    return api().get(`/document-types/${id}`)
  },

  create(payload: Record<string, unknown>): Promise<CreateDocumentTypeResponse> {
    return api().post('/document-types', payload)
  },

  update(id: string | number, payload: Record<string, unknown>): Promise<UpdateDocumentTypeResponse> {
    return api().put(`/document-types/${id}`, payload)
  },

  delete(id: string | number): Promise<DeleteDocumentTypeResponse> {
    return api().delete(`/document-types/${id}`)
  },
}

export default DocumentTypeService
