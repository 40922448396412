













import Vue from 'vue'

export default Vue.extend({
  name: 'DocumentTypeSelect',
  props: { usage: { type: String, default: '' } },
  model: { prop: 'usage' },
  data: function (): {
    items: { label: string; value: string }[]
    loading: boolean
  } {
    return {
      items: [
        { label: this.$tc('client'), value: 'client' },
        { label: this.$tc('payment'), value: 'payment' },
      ],
      loading: false,
    }
  },
  computed: {
    computedType: {
      get(): string {
        return this.usage
      },
      set(value: number): void {
        this.$emit('input', value)
      },
    },
  },
})
